import React from "react"
import PropTypes from "prop-types"

const Tree = ({ color }) => {
    console.log(color)
  return (
        <svg 
        fill={color}
        aria-hidden="true" 
        focusable="false" 
        data-prefix="fas" 
        data-icon="tree" 
        role="img" 
        x="0px"
        y="0px"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 384 512">
        <path 
        // fill={color}
        d="M378.31 378.49L298.42 288h30.63c9.01 0 16.98-5 20.78-13.06 3.8-8.04 2.55-17.26-3.28-24.05L268.42 160h28.89c9.1 0 17.3-5.35 20.86-13.61 3.52-8.13 1.86-17.59-4.24-24.08L203.66 4.83c-6.03-6.45-17.28-6.45-23.32 0L70.06 122.31c-6.1 6.49-7.75 15.95-4.24 24.08C69.38 154.65 77.59 160 86.69 160h28.89l-78.14 90.91c-5.81 6.78-7.06 15.99-3.27 24.04C37.97 283 45.93 288 54.95 288h30.63L5.69 378.49c-6 6.79-7.36 16.09-3.56 24.26 3.75 8.05 12 13.25 21.01 13.25H160v24.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L224 440.45V416h136.86c9.01 0 17.26-5.2 21.01-13.25 3.8-8.17 2.44-17.47-3.56-24.26z">
        </path>
        </svg>
  )
}

Tree.propTypes = {
  color: PropTypes.string,
}

Tree.defaultProps = {
  color: "#000000",
}

export default Tree